/* eslint-disable react/forbid-dom-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControls,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { EventsType, FieldAttrType } from 'Type/Field.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import './FieldNumberWithControls.style.scss';

/** @namespace Theme/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControls {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        handleValueChange: PropTypes.func.isRequired,
        stateValue: PropTypes.number.isRequired,
        isDisabled: PropTypes.bool.isRequired,
    };

    render() {
        const {
            attr,
            attr: { min = 0, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;
        const id = crypto?.randomUUID?.() || Math.floor(Math.random() * Date.now());

        return (
            <>
                <label htmlFor={ `quantity-${id}` } className="QtyLabel">Qty</label>
                <input
                  { ...attr }
                  { ...events }
                  onChange={ (event) => handleValueChange(+event.target.value) }
                  onKeyDown={ (event) => event.stopPropagation() }
                  ref={ (elem) => setRef(elem) }
                  value={ value }
                  type={ FieldType.NUMBER }
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                  id={ `quantity-${id}` }
                />
                <button
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                  onClick={ () => handleValueChange(numberValue + 1) }
                  aria-label={ __('Add') }
                  type={ FieldType.BUTTON }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                  onClick={ () => handleValueChange(numberValue - 1) }
                  aria-label={ __('Subtract') }
                  type={ FieldType.BUTTON }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
