import PropTypes from 'prop-types';

import { FieldType } from 'Component/Field/Field.config';
import { TOOLTIP_MSG } from 'Component/Tooltip/Tooltip.config';
import {
    FieldContainer as SourceFieldContainer,
} from 'SourceComponent/Field/Field.container';
import { MixType, RefType } from 'Type/Common.type';
import {
    EventsType, FieldAttrType, FieldOptionsType, LabelType, ValidationRuleType,
} from 'Type/Field.type';
/** @namespace Theme/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FieldType)),
        tooltipMsg: PropTypes.oneOf(Object.values(TOOLTIP_MSG)),
        attr: FieldAttrType,
        events: EventsType,
        isDisabled: PropTypes.bool,
        mix: MixType,
        options: FieldOptionsType,
        elemRef: RefType,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        updateSelectedValues: PropTypes.func,
        resetFieldValue: PropTypes.func,

        // Validation
        validationRule: ValidationRuleType,
        validateOn: PropTypes.arrayOf(PropTypes.string),
        showErrorAsLabel: PropTypes.bool,

        // Labels
        label: LabelType,
        subLabel: PropTypes.string,
        addRequiredTag: PropTypes.bool,
        hasTooltip: PropTypes.bool,
    };

    static defaultProps = {
        type: FieldType.TEXT,
        tooltipMsg: TOOLTIP_MSG.text,
        attr: {},
        events: {},
        mix: {},
        validationRule: {},
        validateOn: [],
        options: [],
        showErrorAsLabel: true,
        isDisabled: false,
        addRequiredTag: false,
        hasTooltip: false,
        label: '',
        subLabel: '',
        elemRef: null,
        changeValueOnDoubleClick: false,
        isSortSelect: false,
        updateSelectedValues: null,
        resetFieldValue: null,
    };

    containerProps() {
        const {
            events,
            validateOn,
            type,
            tooltipMsg,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            isDisabled,
            mix,
            value,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            hasTooltip,
            changeValueOnDoubleClick,
            isSortSelect,
        } = this.props;
        const { validationResponse, lengthError } = this.state;
        const { validate } = this.containerFunctions;

        // Surrounds events with validation
        const newEvents = { ...events };
        validateOn.forEach((eventName) => {
            const { [eventName]: baseEvent } = events;
            newEvents[eventName] = baseEvent ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            tooltipMsg,
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete,
            },
            value,
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            hasTooltip,
            changeValueOnDoubleClick,
            isSortSelect,
            validationResponse,
            resetFieldValue: this.resetFieldValue.bind(this),
            events: newEvents,
            fieldRef: this.fieldRef,
            setRef: this.setRef.bind(this),
            lengthError,
        };
    }
}

export default FieldContainer;
