/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import PropTypes from 'prop-types';

import CheckMarkIcon from 'Component/CheckMarkIcon';
import { FieldType } from 'Component/Field/Field.config';
import Tooltip from 'Component/Tooltip';
import { TOOLTIP_MSG } from 'Component/Tooltip/Tooltip.config';
import {
    FieldComponent as SourceField,
} from 'SourceComponent/Field/Field.component';
import { MixType } from 'Type/Common.type';
import {
    EventsType,
    FieldAttrType,
    LabelType,
    OptionType,
} from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import './Field.style';

/**
/** @namespace Theme/Component/Field/Component */
export class FieldComponent extends SourceField {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FieldType)).isRequired,
        tooltipMsg: PropTypes.oneOf(Object.values(TOOLTIP_MSG)).isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        options: PropTypes.arrayOf(OptionType).isRequired,
        changeValueOnDoubleClick: PropTypes.bool,
        isSortSelect: PropTypes.bool,
        resetFieldValue: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,

        // Validation
        validate: PropTypes.func.isRequired,
        showErrorAsLabel: PropTypes.bool.isRequired,
        validationResponse: (props, propName, componentName) => {
            const propValue = props[propName];

            if (propValue === null) {
                return;
            }

            if (typeof propValue === 'boolean') {
                return;
            }

            if (typeof propValue === 'object' && !Object.keys(propValue).includes('errorMessages')) {
                throw new Error(
                    // eslint-disable-next-line max-len
                    `${componentName} only accepts null, bool or object of "errorMessages" as "validationResponse", received "${JSON.stringify(propValue)}"`,
                );
            }
        },

        // Labels
        label: LabelType.isRequired,
        subLabel: PropTypes.string.isRequired,
        addRequiredTag: PropTypes.bool.isRequired,
        hasTooltip: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        validationResponse: null,
        changeValueOnDoubleClick: false,
        isSortSelect: false,
    };

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr }
                  { ...inputEvents }
                    // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled }>
                    <CheckMarkIcon />
                </div>
                { label }
            </label>
        );
    }

    // Renders * for required fields
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } } />
        );
    }

    renderTooltip(tooltipMsg) {
        const { hasTooltip } = this.props;

        if (!hasTooltip) {
            return null;
        }

        return <Tooltip tooltipMsg={ tooltipMsg } />;
    }

    render() {
        const {
            type, validationResponse, mix, tooltipMsg,
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                  } }
                  mix={ mix }
                >
                    { type !== FieldType.CHECKBOX && type !== FieldType.RADIO && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                    { this.renderTooltip(tooltipMsg) }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
