import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CheckMarkIcon.style';

/** @namespace Theme/Component/CheckMarkIcon/Component */
export class CheckMarkIconComponent extends PureComponent {
    static propTypes = {
        isMobileLayout: PropTypes.bool,
    };

    static defaultProps = {
        isMobileLayout: false,
    };

    render() {
        const { isMobileLayout } = this.props;

        return (
            <i
              block="CheckMarkIcon"
              elem="Icon icon-check"
              mods={ { isMobileLayout } }
            />
        );
    }
}

export default CheckMarkIconComponent;
