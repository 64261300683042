import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';

/** @namespace Theme/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    handleValueChange(value) {
        try {
            this.fieldRef.value = +value;
        // eslint-disable-next-line no-empty
        } catch {}

        const {
            events: { onChange } = {},
        } = this.props;
        const newValue = this.setValue(+value);

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    componentDidUpdate() {
        const { attr: { min = 0, defaultValue = min } = {} } = this.props;

        if (defaultValue <= 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: min });
        }

        if (defaultValue < min) {
            this.handleInitialLoad(min);
        }
    }
}
